import BallLoading from "components/icons/BallLoading"
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { classNames } from "utils"
import { Interactions, MetricFilter } from "../types"
import { useInteractionProps } from "../utils"

type CircleChartProps = {
    value?: number
    value_out_of?: number
    label?: string
    size: keyof typeof sizes
    color?: `text-${string}`
    className?: string
    loading: boolean
}

const sizes = {
    small: {
        radius: 6,
        strokeWidth: 1,
        titleClassName: "text-lg"
    },
    medium: {
        radius: 17.5,
        titleClassName: "text-xl pb-1",
        strokeWidth: 2
    },
    large: {
        radius: 24.5,
        titleClassName: "text-[1.65rem] pb-4",
        strokeWidth: 3
    }
}

export const CircleChartWithInteractions = ({
    filterdata,
    className,
    ...props
}: CircleChartProps & { filterdata: MetricFilter }) => {
    const { isHovered, isSelected, ...interactions } = useInteractionProps(filterdata)

    return (
        <CircleChart
            {...props}
            {...interactions}
            className={classNames(
                className,
                isHovered !== true && (isHovered === false || isSelected === false) && "opacity-30",
                "cursor-pointer"
            )}
        />
    )
}

export const CircleChart = ({
    value,
    value_out_of,
    label,
    size,
    color = "text-med-blue",
    className,
    loading,
    ...props
}: CircleChartProps & Partial<Interactions>) => {
    let { radius, strokeWidth, titleClassName } = sizes[size]

    radius = radius * 4 - strokeWidth
    strokeWidth = strokeWidth * 4
    value = loading ? 0 : value

    const circumference = radius * 2 * Math.PI
    const percent = !value || !value_out_of ? 0 : Math.round((value / value_out_of) * 100)
    const circleCenter = radius + strokeWidth

    const [dashOffset, setDashoffset] = useState(circumference)

    useEffect(() => {
        // It is not clear to me why, but it is necessary to set the dashOffset as state for the animation to work. -Yotam
        const updatedDashOffset =
            value_out_of && value > value_out_of
                ? 0
                : percent
                ? circumference - (percent / 100) * circumference
                : circumference
        if (dashOffset !== updatedDashOffset) setDashoffset(updatedDashOffset)
    }, [percent])

    return (
        <div
            className={classNames(
                "w-fit inline-flex items-center justify-center overflow-hidden rounded-full -rotate-90 transition-opacity duration-500",
                className
            )}
            {...props}
        >
            <svg width={circleCenter * 2} height={circleCenter * 2} key={value}>
                <circle
                    className="text-gray-300"
                    strokeWidth={strokeWidth}
                    stroke="currentColor"
                    fill="transparent"
                    r={radius}
                    cx={circleCenter}
                    cy={circleCenter}
                />

                {value > 0 && !loading && (
                    <circle
                        className={classNames(color, "transition-all duration-700")}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeLinecap="round"
                        stroke="currentColor"
                        fill="transparent"
                        r={radius}
                        cx={circleCenter}
                        cy={circleCenter}
                        style={{ strokeDashoffset: dashOffset * -1 }}
                    />
                )}
            </svg>
            <div
                className={classNames(
                    "absolute text-sm flex flex-col text-center font-medium rotate-90 gap-0.5 pointer-events-none",
                    color
                )}
            >
                {loading ? (
                    <BallLoading className="w-5 h-7 text-gray-300 bg-transparent" />
                ) : (
                    label && (
                        <span className={classNames(titleClassName, "font-semibold")}>
                            {`${value}/${value_out_of ?? "?"}`}
                        </span>
                    )
                )}
                {label ? (
                    <>
                        {loading ? (
                            <Skeleton className={classNames(size === "large" ? "w-32" : "w-24")} />
                        ) : (
                            <span>{label} </span>
                        )}
                        {value_out_of ? (
                            <span className="text-sm">({percent}%)</span>
                        ) : (
                            <span className="text-xs text-med-blue text-opacity-50">Unknown</span>
                        )}
                    </>
                ) : null}
            </div>
        </div>
    )
}
