import { SVGProps } from "react"
const BallLoading = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        preserveAspectRatio="xMidYMid"
        style={{
            margin: "auto",
            background: "transparent",
            display: "block",
            shapeRendering: "auto"
        }}
        viewBox="0 0 100 100"
        {...props}
    >
        <circle cx={50} cy={20} r={30} fill="currentColor">
            <animate
                attributeName="cy"
                calcMode="spline"
                dur="1s"
                keySplines="0.45 0 0.9 0.55;0 0.45 0.55 0.9"
                keyTimes="0;0.5;1"
                repeatCount="indefinite"
                values="23;77;23"
            />
        </circle>
    </svg>
)
export default BallLoading
