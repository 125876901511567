import Skeleton from "react-loading-skeleton"
import { classNames } from "utils"
import { Interactions, MetricFilter } from "../types"
import { useInteractionProps } from "../utils"

type BarVerticalChartProps = {
    value: number
    value_out_of: number
    fullHeight: number
    label: string
    color: `text-${string}`
    className?: string
    loading: boolean
}

const transitionProps = "transition-all duration-700"

export const BarVerticalChartWithInteractions = ({
    filterdata,
    className,
    ...props
}: BarVerticalChartProps & { filterdata: MetricFilter }) => {
    const { isHovered, isSelected, ...interactions } = useInteractionProps(filterdata)

    return (
        <BarVerticalChart
            {...props}
            {...interactions}
            className={classNames(
                className,
                isHovered !== true && (isHovered === false || isSelected === false) && "opacity-30",
                "cursor-pointer"
            )}
        />
    )
}

export const BarVerticalChart = ({
    value,
    value_out_of,
    label,
    fullHeight,
    color,
    className,
    loading,
    ...props
}: BarVerticalChartProps & Partial<Interactions>) => {
    const percent = value_out_of ? Math.round((value / value_out_of) * 100) : 0
    const height = (fullHeight * percent) / 100

    const minHeight = 4
    const valueHeight = height > minHeight ? height : minHeight

    return (
        <div
            className={classNames(
                "flex flex-col gap-3 text-dark-blue w-16 justify-end items-center transition-all",
                className
            )}
            {...props}
        >
            <div className={classNames("flex flex-col items-center justify-end", color)}>
                <span>{value}</span>
                <span className="mb-3">{`(${percent}%)`}</span>
                <svg height={valueHeight} width={60} className={transitionProps}>
                    <rect
                        width={60}
                        className={classNames("fill-current", transitionProps)}
                        rx={valueHeight > 12 ? "5" : "2"}
                        style={{ height: valueHeight }}
                    />
                </svg>
            </div>

            {loading ? <Skeleton className="w-16 text-gray-300" /> : label}
        </div>
    )
}
