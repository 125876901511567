import useResizeObserver from "hooks/useResizeObserver"
import { CSSProperties, useMemo, useState } from "react"
import { classNames, getTextWidth } from "utils"
import { Interactions, MetricFilter } from "../types"
import { useInteractionProps } from "../utils"

type BarHorizontalChartProps = {
    value: number
    value_out_of: number
    label: string
    color: string
    withBackground?: boolean
    className?: string
}

export const BarHorizontalChartWithInteractions = ({
    filterdata,
    className,
    ...props
}: BarHorizontalChartProps & { filterdata: MetricFilter }) => {
    const { isHovered, isSelected, ...interactions } = useInteractionProps(filterdata)

    return (
        <BarHorizontalChart
            {...props}
            {...interactions}
            className={classNames(
                className,
                isHovered !== true && (isHovered === false || isSelected === false) && "opacity-30",
                "cursor-pointer"
            )}
        />
    )
}

export const BarHorizontalChart = ({
    value,
    value_out_of,
    label,
    color,
    withBackground,
    className,
    ...props
}: BarHorizontalChartProps & Partial<Interactions>) => {
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 })
    const ref = useResizeObserver<HTMLDivElement>(setDimensions)

    const percent = value_out_of ? Math.round((value / value_out_of) * 100) : 0

    const valueString = `${value} (${percent}%)`
    const valueWidth = (percent * dimensions.width) / 100

    const textWidth = useMemo(() => getTextWidth(valueString), [valueString, valueWidth])
    const isTextLonger = value !== 0 && textWidth > valueWidth - 4

    return (
        <div className="relative bottom-0 flex flex-col justify-between gap-1 w-full" ref={ref}>
            {label}
            <div className={classNames("w-fit transition-opacity duration-500", className)} style={{"direction": "ltr"} as CSSProperties} {...props}>
                <svg
                    width={withBackground ? dimensions.width : valueWidth + (isTextLonger ? textWidth + 16 : 0)}
                    className="h-6"
                >
                    {withBackground ? <rect className="text-bg fill-current h-full w-full" rx="5" /> : null}
                    <rect
                        style={{ width: value > 0 ? valueWidth : 0, fill: color }}
                        rx="5"
                        className="h-full transition-all duration-1000"
                    />
                </svg>
                <div
                    className={classNames(
                        "absolute bottom-0 mx-2 transition transform duration-700 pointer-events-none font-medium",
                        isTextLonger || !value ? "text-dark-blue" : "text-white"
                    )}
                    style={
                        {
                            "--tw-translate-x": isTextLonger && `calc(${valueWidth}px + .2rem)`,
                            color: isTextLonger || !value ? color : undefined
                        } as CSSProperties
                    }
                >
                    {valueString}
                </div>
            </div>
        </div>
    )
}
