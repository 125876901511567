import { noop } from "lodash"
import { createContext, useContext } from "react"
import { DashboardFilters, SetFilterFn } from "./types"

type ContextValue = {
    filters: DashboardFilters.Interactions
    setHovered: SetFilterFn
    setSelected: SetFilterFn
}

export const TherapistDashboardContext = createContext<ContextValue>({
    filters: {},
    setHovered: noop,
    setSelected: noop
})

export const useTherapistDashboardContext = () => useContext(TherapistDashboardContext)
